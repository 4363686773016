<template>
  <nav :class="classes">
    <component
      v-for="(item, i) in menu"
      :key="i"
      :is="getTag(item)"
      :class="[$style.item, isActive(item) && $style.active]"
      v-bind="item.args"
      @click="onClick(item)"
    >
      <span :class="$style.text">{{ item.title }}</span>
      <span :class="$style.count" v-if="item.count">
        <span :class="$style.counterInner">{{ item.count }}</span>
      </span>
    </component>
  </nav>
</template>

<script>
import UiLink from '~/components/ui/link'

export default {
  name: 'UiMenu',
  components: {
    UiLink
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ['click'],
  computed: {
    classes() {
      return [this.$style.menu]
    },
    menu() {
      return this.items.map(item => ({
        ...item,
        active: this.isActive(item),
        tag: this.getTag(item),
        args: item.to ? { to: item.to } : item.href ? { href: item.href } : {}
      }))
    }
  },
  methods: {
    onClick(item) {
      this.$emit('click', item)
    },
    getTag(item) {
      if (item.href) {
        return 'a'
      } else if (item.to) {
        return 'ui-link'
      } else {
        return 'button'
      }
    },
    isActive(item) {
      return !!item.active
    }
  }
}
</script>

<style lang="scss" module>
.menu {
  display: flex;
  align-items: center;
}
.item {
  padding: 0 1.2em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  background: transparent;
  color: #7e8197;
  border-radius: 0.4em;
  transition:
    background 0.2s,
    color 0.2s;
  cursor: pointer;
  margin-right: 0.8em;
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 1.6em;
    line-height: 212%;
    letter-spacing: -0.02em;
  }
  .count {
    width: 3em;
    height: 2em;
    color: var(--app-color-text);
    background: var(--app-transparent-white);
    border-radius: 2em;
    line-height: 2em;
    margin-left: 0.6em;

    .counterInner {
      font-size: 1.4em;
    }
  }
  &:hover {
    background-color: #191c32;
    color: #ffffff;
  }
  &.active {
    color: var(--app-active-color);
    pointer-events: none;

    .count {
      background: var(--app-active-color);
      color: var(--app-color-black);
    }
  }
}
</style>
